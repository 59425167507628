import {Box, BoxProps, Typography, useTheme} from "@mui/material";
import Stack from "@mui/system/Stack";
import React from "react";
import {ResponsiveStyleValue} from "@mui/system/styleFunctionSx";

interface OrderRowProps {
    title: string;
    text: string;
    direction?: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
}

export function OrderRow(
    { title, text, direction="row", ...props }: OrderRowProps & BoxProps
) {
    const theme = useTheme();
    return (
        <Box {...props}>
            <Stack direction={direction}>
                <Typography
                    variant="body1"
                    color={theme.palette.secondary.main}
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical"
                    }}
                >
                    {title}:
                </Typography>
                <Typography
                    variant="body1"
                    color={theme.palette.primary.main}
                    ml={direction === "row" ? "0.5rem" : "0px"}
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: direction === "row" ? "1" : "5",
                        WebkitBoxOrient: "vertical"
                    }}
                >
                    {text}
                </Typography>
            </Stack>
        </Box>
    );
}
