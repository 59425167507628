import React, {useContext, useState} from "react";
import Stack from "@mui/system/Stack";
import {TopBar} from "../../widgets/top-bar";
import {Box, Typography, useTheme} from "@mui/material";
import {OrderContext, TokenContext} from "../../context";
import {MuiLoadingButtonCustom} from "../../theme";
import {OrderRow} from "../../widgets/order-row";
import formatPhone from "../../utils/phone";
import {makeOrder} from "../../model/order";

export function OrderPage() {
    const theme = useTheme();

    const [order] = useContext(OrderContext);
    const [token] = useContext(TokenContext);
    const [loading, setLoading] = useState<boolean>();

    const onOrder = async () => {
        if (!token) {
            return;
        }
        setLoading(true);
        const r = await makeOrder(token, order);
        setLoading(false);
        if (r) {
            window.location.href = r.toString();
        }
    };

    const burnDate = new Date();
    const validityPeriod = order?.nominal?.validity_period;
    if (validityPeriod !== null) {
        burnDate.setDate(burnDate.getDate() + validityPeriod!);
    }

    return (
        <Box height="100vh">
            <Stack bgcolor={theme.palette.background.paper}>
                <TopBar
                    disabled={loading}
                />
                <Stack px="2rem">
                    <Box
                        sx={{
                            mt: "1rem",
                            maxWidth: 500,
                            alignSelf: "center",
                            position: "relative"
                        }}
                    >
                        <img
                            src={order.image!}
                            alt="card"
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "card_placeholder.png";
                            }}
                            style={{
                                borderRadius: "1rem",
                                aspectRatio: 1.586,
                                maxWidth: "100%"
                            }}
                        />
                        <Box
                            position="absolute"
                            bottom="1rem"
                            right="1rem"
                            sx={{
                                backgroundColor: "#474748",
                                borderRadius: "0.6rem",
                                px: "1rem",
                                py: "0.25rem"
                            }}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    color: "white",
                                }}
                            >
                                {`${order.nominal?.value} ₽`}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        mt="1.5rem"
                        variant="h2">
                        ЭЛЕКТРОННАЯ ПОДАРОЧНАЯ КАРТА
                    </Typography>
                    <Box mt="1rem"/>
                    {
                        order.receiver.name ? <OrderRow
                            title="Получатель"
                            text={order.receiver.name}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.toMyself ? null : <OrderRow
                            title="На номер"
                            text={formatPhone(order.receiver.phone)}
                            mt="0.5rem"
                        />
                    }
                    {
                        order.receiver.email ? <OrderRow
                            title="E-mail"
                            text={order.receiver.email}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.wishes ? <OrderRow
                            title="Текст пожелания"
                            text={order.wishes}
                            direction="column"
                            mt="1rem"
                        /> : null
                    }
                    {
                        order.receiver.name || order.receiver.phone || order.receiver.email ? <Box mt="1rem" /> : null
                    }
                    {
                        order.sender.name && !order.toMyself && !order.anonymously ? <OrderRow
                            title="От"
                            text={order.sender.name}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.sender.phone ? <OrderRow
                            title="Телефон"
                            text={formatPhone(order.sender.phone)}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.sender.email ? <OrderRow
                            title="E-mail"
                            text={order.sender.email}
                            mt="0.5rem"
                        /> : null
                    }
                    <Box mt="1rem" />
                    <OrderRow
                        title="Срок действия"
                        // text={moment(burnDate).format('DD.MM.YYYY')}
                        text={`${validityPeriod} дней`}
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Номинал"
                        text={`${order.nominal?.value.toString()} ₽`}
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Сумма к оплате"
                        text={`${order.nominal?.cost.toString()} ₽`}
                        mt="0.5rem"
                        mb="5rem"
                    />
                </Stack>
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <MuiLoadingButtonCustom
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    onClick={onOrder}
                    sx={{
                        mb: "1rem",
                        mt: "0.5rem"
                    }}
                >
                    {`Оплатить ${order.nominal?.cost} ₽`}
                </MuiLoadingButtonCustom>
            </Box>
        </Box>
    );
}
