import {TextFieldCustom} from "../theme";
import React, {ChangeEvent, useContext} from "react";
import {TextFieldProps} from "@mui/material";
import {OrderContext} from "../context";
import {emailRegexp, phoneRegexp} from "../utils/regexp";
import InputMask from "@mona-health/react-input-mask";

interface InputProps {
    isSender: boolean;
    isError?: boolean;
    onErrorClean?: (() => void) | null;
}

export function NameInput({
    isSender, isError=false, onErrorClean=null, ...props
}: InputProps & TextFieldProps) {
    const [order, setOrder] = useContext(OrderContext);
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const o = {
            ...order,
            sender: isSender ? {
                ...order.sender,
                name: event.target.value
            } : order.sender,
            receiver: !isSender ? {
                ...order.receiver,
                name: event.target.value
            } : order.receiver,
        };
        setOrder(o);
        if (event.target.value.length > 1 && onErrorClean) {
            onErrorClean();
        }
    };
    return <TextFieldCustom
        variant="standard"
        label={isSender ? "Ваше имя *" : "Имя получателя"}
        error={isError}
        helperText={isError && isSender ? "Укажите имя" : ""}
        value={isSender ? order.sender.name : order.receiver.name}
        onChange={onChange}
        {...props}
    />;
}

export function EmailInput({
    isSender, isError=false, onErrorClean=null, ...props
}: InputProps & TextFieldProps) {
    const [order, setOrder] = useContext(OrderContext);
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const o = {
            ...order,
            sender: isSender ? {
                ...order.sender,
                email: event.target.value
            } : order.sender,
            receiver: !isSender ? {
                ...order.receiver,
                email: event.target.value
            } : order.receiver,
        };
        setOrder(o);
        if ((!event.target.value || emailRegexp.test(event.target.value)) && onErrorClean) {
            onErrorClean();
        }
    };
    return <TextFieldCustom
        variant="standard"
        label={isSender ? "Ваш E-mail *" : "E-mail получателя"}
        error={isError}
        helperText={isError && isSender ? "Укажите e-mail адрес" : ""}
        value={isSender ? order.sender.email : order.receiver.email}
        onChange={onChange}
        {...props}
    />;
}

export function PhoneInput({
    isSender, isError=false, onErrorClean=null, ...props
}: InputProps & TextFieldProps) {
    const [order, setOrder] = useContext(OrderContext);
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value
            .replace(/\s/g, "")
            .replace("(", "")
            .replace(")", "")
            .replace("+", "");
        if (value.length > 10 && !phoneRegexp.test(value)) {
            return;
        }
        const o = {
            ...order,
            sender: isSender ? {
                ...order.sender,
                phone: value
            } : order.sender,
            receiver: !isSender ? {
                ...order.receiver,
                phone: value
            } : order.receiver,
        };
        setOrder(o);
        if (phoneRegexp.test(value) && onErrorClean) {
            onErrorClean();
        }
    };
    return <InputMask
        mask="+7 (999) 999 99 99"
        maskPlaceholder=" "
        value={isSender ? order.sender.phone.substring(1) : order.receiver.phone.substring(1)}
        onChange={onChange}
        {...props}
    >
        <TextFieldCustom
            variant="standard"
            label={isSender ? "Ваш телефон *" : "Телефон получателя *"}
            error={isError}
            helperText={isError ? "Укажите номер телефона" : ""}
        />
    </InputMask>;
}
