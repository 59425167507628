import {MuiToolbarCustom} from "../theme";
import {AppBar, Box, IconButton, useTheme} from "@mui/material";
import {BurgerClose} from "react-burger-icons";
import React, {useContext} from "react";
import {CompanyContext, DrawerContext} from "../context";
import {useNavigate} from "react-router-dom";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

interface TopBarProps {
    main?: boolean;
    disabled?: boolean;
    final?: boolean;
}

export function TopBar(
    { main = false, disabled = false, final = false }: TopBarProps
) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isDrawerClosed, setIsDrawerClosed] = useContext(DrawerContext);
    const [company] = useContext(CompanyContext);
    return (
        <AppBar
            position="sticky"
            elevation={0}
            sx={{zIndex: theme.zIndex.drawer + 1}}
        >
            <MuiToolbarCustom>
                <Box
                    position="absolute"
                    left="0"
                    right="0"
                >
                    <Box
                        sx={{
                            backgroundColor: "#F8F8F8",
                            width: "12rem",
                            aspectRatio: 182 / 50,
                            mx: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img
                            src={company?.logotype ?? 'logotype.svg'}
                            alt="logotype"
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "card_placeholder.png";
                            }}
                            width={"100%"}
                            height={"100%"}
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                </Box>
                {
                    final ? null : <IconButton
                        disabled={disabled}
                        size="large"
                        edge="start"
                        aria-label="menu"
                        onClick={() => {
                            if (main) {
                                setIsDrawerClosed(!isDrawerClosed);
                            } else {
                                navigate(-1);
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            width="2rem"
                            height="2rem"
                        >
                            {
                                main ? <BurgerClose isClosed={!isDrawerClosed} /> :
                                    <ArrowBackIosRoundedIcon sx={{color: theme.palette.primary.main}}/>
                            }
                        </Box>
                    </IconButton>
                }
            </MuiToolbarCustom>
        </AppBar>
    );
}

interface TopBarSimpleProps {
    logotype?: string;
}

export function TopBarSimple({logotype}: TopBarSimpleProps) {
    const theme = useTheme();
    return (
        <AppBar
            position="sticky"
            elevation={0}
            sx={{zIndex: theme.zIndex.drawer + 1}}
        >
            <MuiToolbarCustom>
                <Box
                    position="absolute"
                    left="0"
                    right="0"
                >
                    <Box
                        sx={{
                            backgroundColor: "#F8F8F8",
                            width: "12rem",
                            aspectRatio: 182 / 50,
                            mx: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img
                            src={logotype ?? 'logotype.svg'}
                            alt="logotype"
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "card_placeholder.png";
                            }}
                            width={"100%"}
                            height={"100%"}
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                </Box>
            </MuiToolbarCustom>
        </AppBar>
    );
}
