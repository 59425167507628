import {Typography, TypographyProps} from "@mui/material";
import React from "react";

interface TitleTextProps {
    title: string;
}

export default function TitleText({title, ...props}: TitleTextProps & TypographyProps) {
    return (
        <Typography
            variant="h1"
            alignSelf="center"
            {...props}
        >
            {title}
        </Typography>
    );
}
