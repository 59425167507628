import React, {useEffect, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Loading from "../../widgets/loading";

export function OfferAgreementPage() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [offerAgreement, setOfferAgreement] = useState<String | null>(null);
    const hrefSplit = window.location.href.split("/");
    const token = hrefSplit.length > 1 ? hrefSplit.slice(-1)[0] : null;
    useEffect(() => {
        const BASE_URL = process.env.REACT_APP_API_URL;
        const fetchData = async () => {
            if (!token) {
                return;
            }
            const r: String = (
                await axios.get(`${BASE_URL}/public/offer_agreement/${token}`)
                // await axios.get(`https://seanoshea.me/?ref=onepagelove`)
            ).data;
            setOfferAgreement(r);
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    });
    if (isLoading || offerAgreement === null) {
        return <Loading />;
    }
    return (
        <div
            dangerouslySetInnerHTML={{__html:offerAgreement.toString()}}
            style={{
                fontFamily: "Inter",
                fontSize: "1rem",
                color: "#474748"
            }}
        />
    );
}
