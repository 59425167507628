import {Box, CircularProgress, Container, useTheme} from "@mui/material";
import React from "react";

export default function Loading() {
    const theme = useTheme();
    return <Container
        maxWidth="lg"
        sx={{
            backgroundColor: theme.palette.background.paper
        }}
    >
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </Box>
    </Container>;
}
