import "./App.css";
import {CardContext, CompanyContext, GlobalContext, OrderContext, TokenContext} from "./context";
import React, {useEffect, useState} from "react";
import {Order} from "./model/order";
import Company from "./model/company";
import axios from "axios";
import {Card, CardPerson} from "./model/card";
import MediaQuery from 'react-responsive'
import {MobileRootPage} from "./pages/mobile/root";
import {DesktopRootPage} from "./pages/desktop/root";
import GlobalState from "./model/global-state";
import Loading from "./widgets/loading";
import NotFound from "./widgets/NotFound";

export function App() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const hrefSplit = window.location.href.split("/");
    const [globalState, setGlobalState] = useState<GlobalState>(new GlobalState());
    const token_ = hrefSplit.length > 1 ? hrefSplit.slice(-1)[0] : null;
    const [token, setToken] = useState<String | null>(token_);
    const [order, setOrder] = useState<Order>(new Order());
    const [company, setCompany] = useState<Company | null>(null);
    const [card, setCard] = useState<Card | null>(null);

    useEffect(() => {
        const BASE_URL = process.env.REACT_APP_API_URL;
        const fetchData = async () => {
            const company: Company = (
                await axios.get(`${BASE_URL}/public/info/${token_}`)
            ).data;
            setCompany(company);
            if (company.images.length > 0) {
                setOrder(o => {
                    if (o.image) {
                        return o;
                    }
                    return {
                        ...o,
                        image: company.images[0]
                    };
                });
            }
            const card: Card = (
                await axios.get(`${BASE_URL}/public/cards/info/${token_}`)
            ).data;
            setCard(card);
            if (card.activated === null) {
                const addedImages = []
                if (!company.images.includes(card.image)) {
                    addedImages.push(card.image);
                }
                const toMyself = card.giver.phone === card.getter.phone;
                setOrder({
                    image: card.image,
                    nominal: company.nominals.find((e) => e.value === card.nominal)!,
                    wishes: card.message,
                    toMyself: toMyself,
                    anonymously: false,
                    sender: card.giver,
                    receiver: toMyself ? new CardPerson() : card.getter,
                    addedImages: addedImages
                })
            }
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    }, [token_, setCompany, setCard, setIsLoading, setOrder]);
    if (!token_) {
        return <NotFound />;
    }

    if (isLoading) {
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        );
    }

    return (
        <GlobalContext.Provider
            value={[globalState, setGlobalState]}
        >
            <TokenContext.Provider
                value={[token, setToken]}
            >
                <OrderContext.Provider
                    value={[order, setOrder]}
                >
                    <CompanyContext.Provider
                        value={[company, setCompany]}
                    >
                        <CardContext.Provider
                            value={[card, setCard]}
                        >
                            <div>
                                <MediaQuery minWidth={800}>
                                    <DesktopRootPage />
                                </MediaQuery>
                                <MediaQuery maxWidth={800}>
                                    <MobileRootPage />
                                </MediaQuery>
                            </div>
                        </CardContext.Provider>
                    </CompanyContext.Provider>
                </OrderContext.Provider>
            </TokenContext.Provider>
        </GlobalContext.Provider>
    );
}
