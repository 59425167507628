import React, {useContext, useState} from "react";
import Stack from "@mui/system/Stack";
import {Box} from "@mui/material";
import {OrderContext} from "../../context";
import {MuiButtonCustom} from "../../theme";
import {useLocation, useNavigate} from "react-router-dom";
import Cropper, {Area} from "react-easy-crop";
import {useImageSize} from "react-image-size";
import getCroppedImg from "../../utils/crop";

export function ImageCropperPage() {
    const navigate = useNavigate();
    const [order, setOrder] = useContext(OrderContext);
    const {state} = useLocation();
    const imageUrl = state.imageUrl;
    const [dimensions, ] = useImageSize(imageUrl);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedArea, setCroppedArea] = useState<Area>()

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels)
    }

    const onApply = async () => {
        if (croppedArea) {
            const croppedImage = await getCroppedImg(imageUrl, croppedArea);
            if (croppedImage === null) {
                return;
            }
            const o = {
                ...order,
                image: croppedImage,
                addedImages: order.addedImages ? order.addedImages.concat([croppedImage]) : [croppedImage]
            };
            setOrder(o);
            navigate(-1);
        }
    };

    return (
        <Box
            sx={{
                width: "600px",
                mx: "auto",
                my: "2rem"
            }}
        >
            <Stack>
                <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={dimensions?.width ? dimensions?.width / 1024 > 1.0 ? dimensions?.width / 1024 : 1.0 : 1.0}
                    aspect={1.586}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    style={{
                        containerStyle: {
                            width: "100%",
                            aspectRatio: 1,
                            position: "relative",
                            borderRadius: "1rem",
                        },
                        cropAreaStyle: {
                            borderRadius: "2rem",
                            color: "#bebebe99",
                            backgroundColor: "transparent"
                        }
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: "2rem"
                    }}
                >
                    <MuiButtonCustom
                        variant="contained"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Отмена
                    </MuiButtonCustom>
                    <MuiButtonCustom
                        variant="contained"
                        onClick={onApply}
                        sx={{
                            ml: "1rem"
                        }}
                    >
                        Применить
                    </MuiButtonCustom>
                </Box>
            </Stack>
        </Box>
    );
}
