import React, {useContext, useState} from "react";
import Stack from "@mui/system/Stack";
import {TopBar} from "../../widgets/top-bar";
import {Box} from "@mui/material";
import {OrderContext} from "../../context";
import {MuiButtonCustom} from "../../theme";
import {useLocation, useNavigate} from "react-router-dom";
import Cropper, {Area} from "react-easy-crop";
import TitleText from "../../widgets/title-text";
import {useImageSize} from "react-image-size";
import getCroppedImg from "../../utils/crop";

export function ImageCropperPage() {
    const navigate = useNavigate();
    const [order, setOrder] = useContext(OrderContext);
    const {state} = useLocation();
    const imageUrl = state.imageUrl;
    const [dimensions, ] = useImageSize(imageUrl);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedArea, setCroppedArea] = useState<Area>()

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels)
    }

    const onApply = async () => {
        if (croppedArea) {
            const croppedImage = await getCroppedImg(imageUrl, croppedArea);
            if (croppedImage === null) {
                return;
            }
            const o = {
                ...order,
                image: croppedImage,
                addedImages: order.addedImages ? order.addedImages.concat([croppedImage]) : [croppedImage]
            };
            setOrder(o);
            navigate(-1);
        }
    };

    return (
        <Box height="100vh">
            <Stack>
                <TopBar />
                <TitleText
                    title="Дизайн карты"
                    sx={{
                        mt: '0.875rem',
                        mb: '1rem',
                        mx: '2rem',
                        alignSelf: 'start'
                    }}
                />
                <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={dimensions?.width ? dimensions?.width / 1024 > 1.0 ? dimensions?.width / 1024 : 1.0 : 1.0}
                    aspect={1.586}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    style={{
                        containerStyle: {
                            width: "100%",
                            aspectRatio: 1,
                            position: "relative"
                        },
                        cropAreaStyle: {
                            borderRadius: "2rem",
                            color: "#7c7c7c66",
                            backgroundColor: "transparent"
                        }
                    }}
                />
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <MuiButtonCustom
                    variant="contained"
                    onClick={onApply}
                    sx={{
                        mb: "1rem",
                        mt: "0.5rem"
                    }}
                >
                    Применить
                </MuiButtonCustom>
            </Box>
        </Box>
    );
}
