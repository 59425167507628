import Grid from "@mui/material/Grid2";
import Stack from "@mui/system/Stack";
import {Box, FormControl, FormControlLabel, IconButton, Typography, useTheme} from "@mui/material";
import {
    CheckboxCustom,
    MuiButtonCustom,
    MuiLoadingButtonCustom,
    SkeletonCustom,
    TextareaAutosizeCustom
} from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import {NominalSelector} from "../../widgets/nominal-selector";
import GiftForm from "./gift-form";
import GiftSubjectSelector from "./gift-subject-selector";
import {isOrderValid, makeOrder} from "../../model/order";
import React, {ChangeEvent, useContext, useRef, useState} from "react";
import {CompanyContext, GlobalContext, OrderContext, TokenContext} from "../../context";
import {useNavigate} from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRounded from "@mui/icons-material/ArrowForwardIosRounded";
import {NotificationSnackBar} from "../../widgets/snackbar";
import {FormState} from "../../model/form-state";

export function MainPage() {
    const navigate = useNavigate();
    const theme = useTheme();

    const [order, setOrder] = useContext(OrderContext);
    const [company] = useContext(CompanyContext);
    const [globalState, setGlobalState] = useContext(GlobalContext);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [toShowNotification, setToShowNotification] = useState<boolean>(false);
    const [toShowFormNotification, setToShowFormNotification] = useState<boolean>(false);
    const [toShowNominalNotification, setToShowNominalNotification] = useState<boolean>(false);
    const [toShowPersonalDataNotification, setToShowPersonalDataNotification] = useState<boolean>(false);

    const [personalDataError, setPersonalDataError] = useState<boolean>(false);

    const [token] = useContext(TokenContext);
    const [loading, setLoading] = useState<boolean>();

    const [formState, setFormState] = useState<FormState>(new FormState());

    const onWishesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const o = {
            ...order,
            wishes: event.target.value
        };
        setOrder(o);
    }

    const onIsPersonalDataConditionsAcceptedChange = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
        setPersonalDataError(false);
        setToShowPersonalDataNotification(false);
        const gs = {
            ...globalState,
            isPersonalDataConditionsAccepted: value
        }
        setGlobalState(gs);
    };

    const onOrder = async () => {
        if (!token) {
            return;
        }
        const orderValid = isOrderValid(order);
        setFormState({
            sender: {
                name: orderValid.sender.isNameValid,
                phone: orderValid.sender.isPhoneValid,
                email: orderValid.sender.isEmailValid
            },
            receiver: {
                ...formState.receiver,
                phone: orderValid.receiver.isPhoneValid
            }
        });
        if (orderValid.onlyNominalNotValid()) {
            setToShowNominalNotification(true);
            return;
        }
        if (!orderValid.isAllValid()) {
            setToShowFormNotification(true);
            return;
        }
        if (!globalState.isPersonalDataConditionsAccepted) {
            setPersonalDataError(true);
            setToShowPersonalDataNotification(true);
            return;
        }
        setLoading(true);
        const r = await makeOrder(token, order);
        setLoading(false);
        if (r) {
            window.location.href = r.toString();
        }
    };

    let images = company?.images.concat(order.addedImages) ?? [];

    return <Grid
        container
        spacing={3}
    >
        <Grid size={8}>
            <Stack
                sx={{
                    borderRadius: "2rem",
                    backgroundColor: "#F8F8F8"
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                        color: "#474748",
                        mt: "2rem",
                        mx: "3.75rem",
                        alignSelf: "start"
                    }}
                >
                    Выберите или загрузите свой
                </Typography>
                {
                    images.length > 1 ? <Box mt="1.5rem">
                        <div
                            className="slider-container"
                        >
                            <Slider
                                className={"center"}
                                centerMode={true}
                                infinite={true}
                                centerPadding={"0px"}
                                slidesToShow={images.length === 3 ? 2.33 : 3}
                                speed={200}
                                dots={true}
                                initialSlide={order.image ? images.indexOf(order.image) : 0}
                                adaptiveHeight={false}
                                focusOnSelect={false}
                                rows={1}
                                nextArrow={
                                    <IconButton
                                        className={"slick-next"}
                                        sx={{
                                            color: "#474748"
                                        }}
                                    >
                                        <ArrowForwardIosRounded />
                                    </IconButton>
                                }
                                prevArrow={
                                    <IconButton
                                        className={"slick-prev"}
                                        sx={{
                                            color: "#474748",
                                        }}
                                    >
                                        <ArrowBackIosRoundedIcon />
                                    </IconButton>
                                }
                                afterChange={(index: number) => {
                                    const o = {
                                        ...order,
                                        image: images[index]
                                    };
                                    setOrder(o);
                                }}
                            >
                                {
                                    images.map((image) => (
                                        <Box
                                            key={image}
                                            sx={{
                                                borderRadius: "1rem",
                                                aspectRatio: 1.586,
                                                position: "relative"
                                            }}
                                        >
                                            <img
                                                src={image}
                                                alt="slide"
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "card_placeholder.png";
                                                }}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "1rem"
                                                }}
                                            />
                                            {
                                                order.nominal !== null && image === order.image ? <Box
                                                    position="absolute"
                                                    bottom="1rem"
                                                    right="0%"
                                                    sx={{
                                                        backgroundColor: "#474748",
                                                        borderBottomLeftRadius: "0.5rem",
                                                        borderTopLeftRadius: "0.5rem",
                                                        px: "1rem",
                                                        py: "0.25rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Inter",
                                                            fontSize: "1rem",
                                                            fontWeight: 600,
                                                            color: "white",
                                                        }}
                                                    >
                                                        {`${order.nominal?.value} ₽`}
                                                    </Typography>
                                                </Box> : null
                                            }
                                        </Box>
                                    ))
                                }
                            </Slider>
                        </div>
                    </Box> : images.length > 0 ? <img
                        src={images[0]}
                        alt={"card"}
                        style={{
                            marginTop: "1.5rem",
                            marginLeft: "2rem",
                            marginRight: "2rem",
                            borderRadius: "1rem",
                            aspectRatio: 1.586,
                            width: "40%",
                            alignSelf: "center"
                        }}
                    /> : <Box
                        sx={{
                            mx: "2rem",
                            mt: "1.5rem",
                            width: "40%",
                            aspectRatio: 1.586,
                            alignSelf: "center"
                        }}
                    >
                        <SkeletonCustom
                            sx={{
                                height: "100%"
                            }}
                        />
                    </Box>
                }
                <Box
                    sx={{
                        mt: "2.5rem",
                        mb: "2rem",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <MuiButtonCustom
                        variant="contained"
                        startIcon={<AddIcon/>}
                        disabled={company === null}
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}
                    >
                        Мой дизайн
                    </MuiButtonCustom>
                </Box>
            </Stack>
            <Stack
                sx={{
                    borderRadius: "2rem",
                    backgroundColor: "#F8F8F8",
                    mt: "0.25rem"
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                        color: "#474748",
                        mt: "2rem",
                        mx: "3.75rem",
                        alignSelf: "start"
                    }}
                >
                    Выберите номинал
                </Typography>
                <NominalSelector
                    sx={{
                        mt: "1rem",
                        mb: "2rem"
                    }}
                />
            </Stack>
            <Box
                sx={{
                    borderRadius: "2rem",
                    backgroundColor: "#F8F8F8",
                    mt: "0.25rem"
                }}
            >
                {
                    globalState.isGiftSubjectSelected ? <GiftForm
                        formState={formState}
                        onChange={(s) => setFormState(s)}
                    /> : <GiftSubjectSelector/>
                }
            </Box>
        </Grid>
        <Grid size={4}>
            <Stack sx={{
                py: "0.5rem"
            }}>
                {
                    order.image ? <Box
                        position="relative"
                    >
                        <img
                            src={order.image}
                            alt={"card_preview"}
                            style={{
                                borderRadius: "1rem",
                                aspectRatio: 1.586,
                                width: "100%",
                                boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.15)"
                            }}
                        />
                        {
                            order.nominal !== null ? <Box
                                position="absolute"
                                bottom="1rem"
                                right="0%"
                                sx={{
                                    backgroundColor: "#474748",
                                    borderBottomLeftRadius: "0.75rem",
                                    borderTopLeftRadius: "0.75rem",
                                    px: "1.5rem",
                                    py: "0.5rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "1.25rem",
                                        fontWeight: 600,
                                        color: "white",
                                    }}
                                >
                                    {`${order.nominal?.value} ₽`}
                                </Typography>
                            </Box> : null
                        }
                    </Box> : <Box
                        sx={{
                            width: "100%",
                            aspectRatio: 1.586
                        }}
                    >
                        <SkeletonCustom
                            sx={{
                                height: "100%"
                            }}
                        />
                    </Box>
                }
                <TextareaAutosizeCustom
                    value={order.wishes}
                    onChange={onWishesChange}
                    minRows={2}
                    maxLength={400}
                    placeholder="Введите текст"
                    sx={{
                        mt: "2rem"
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontWeight: 400,
                        color: "#BEBEBE",
                        mt: "0.375rem",
                    }}
                >
                    {`${order.wishes.length} / 400`}
                </Typography>
                <FormControl
                    error={personalDataError}
                >
                    <FormControlLabel
                        sx={{
                            mt: "2.5rem",
                            fontSize: "0.8125rem",
                            fontWeight: 600,
                            color: personalDataError ? theme.palette.error.main : theme.palette.primary.main,
                        }}
                        control={
                            <CheckboxCustom
                                color={personalDataError ? "error" : "primary"}
                                checked={globalState.isPersonalDataConditionsAccepted}
                                onChange={onIsPersonalDataConditionsAcceptedChange}
                            />
                        }
                        label={
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "0.75rem",
                                    fontWeight: 400,
                                    textDecoration: personalDataError ? 'underline': null,
                                    color: personalDataError ? theme.palette.error.main : "#474748",
                                }}
                            >
                                Принимаю условия обработки персональных данных
                            </Typography>
                        }
                    />
                    {/*{*/}
                    {/*    personalDataError ? <FormHelperText*/}
                    {/*        sx={{*/}
                    {/*            fontSize: "0.7rem",*/}
                    {/*            fontWeight: 500,*/}
                    {/*            lineHeight: 1.,*/}
                    {/*            color: theme.palette.error.main*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Ознакомьтесь с условиями обработки персональных данных*/}
                    {/*    </FormHelperText> : null*/}
                    {/*}*/}
                </FormControl>
                <MuiLoadingButtonCustom
                    variant="contained"
                    loading={loading}
                    disabled={false}
                    onClick={onOrder}
                    sx={{
                        my: "1rem"
                    }}
                >
                    {order.nominal ? `Оплатить ${order.nominal.cost} ₽` : "Оплатить"}
                </MuiLoadingButtonCustom>
            </Stack>
        </Grid>
        <label>
            <input
                hidden
                ref={fileInputRef}
                type='file'
                accept=".jpg, .jpeg, .png"
                // capture="environment"
                onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                        const url = URL.createObjectURL(event.target.files[0]);
                        const img = new Image();
                        img.src = url;
                        img.onload = () => {
                            if (img.width < 1024 || img.height < 1024 / 1.586) {
                                setToShowNotification(true);
                                return;
                            }
                            navigate(
                                "/image_cropper",
                                {state: {imageUrl: url}}
                            );
                        };
                    }
                }}
            />
        </label>
        <NotificationSnackBar
            type={"error"}
            open={toShowNotification}
            onClose={() => setToShowNotification(false)}
            message={"Размер изображения должен быть не менее 1024x645"}
        />
        <NotificationSnackBar
            type={"warning"}
            open={toShowNominalNotification}
            onClose={() => setToShowNominalNotification(false)}
            message={"Укажите номинал"}
        />
        <NotificationSnackBar
            type={"warning"}
            open={toShowFormNotification}
            onClose={() => setToShowFormNotification(false)}
            message={"Заполните данные"}
        />
        <NotificationSnackBar
            type={"warning"}
            open={toShowPersonalDataNotification}
            onClose={() => setToShowPersonalDataNotification(false)}
            message={"Ознакомьтесь с условиями обработки персональных данных"}
        />
    </Grid>;
}
