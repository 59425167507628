export class PersonFormState {
    name: boolean = true;
    phone: boolean = true;
    email: boolean = true;
}

export class FormState {
    sender: PersonFormState = new PersonFormState();
    receiver: PersonFormState = new PersonFormState();
}
