import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography} from "@mui/material";
import React, {useContext} from "react";
import {CompanyContext, DrawerContext} from "../context";
import formatPhone from "../utils/phone";

export function LeftDrawer() {
    const [isDrawerClosed, setIsDrawerClosed] = useContext(DrawerContext);
    const [company] = useContext(CompanyContext);
    const DrawerList = (
        <Box
            sx={{
                width: 250,
                height: "100%",
                position: "relative",
                mb: "4.3rem",
                bgcolor: "white",
                borderRadius: "1.25rem",
            }}
            role="presentation"
            onClick={() => setIsDrawerClosed(true)}
        >
            <Toolbar />
            <Divider />
            <List>
                {["Правила", "Оферта"].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton
                            sx={{
                                px: "2rem",
                                py: "0.8rem"
                            }}
                        >
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        px: "2rem",
                        mt: "auto",
                    }}
                >
                    Поддержка
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        px: "2rem",
                        py: "0.25rem",
                        color: "black"
                    }}
                    onClick={() => {
                        if (company?.support_phone) {
                            window.location.href = `tel:+${company?.support_phone}`;
                        }
                    }}
                >
                    {
                        formatPhone(company?.support_phone)
                    }
                </Typography>
                <Divider sx={{mt: "2rem"}}/>
                <Typography
                    variant="note"
                    sx={{
                        px: "2rem",
                        pt: "0.75rem",
                        pb: "0.0625rem",
                    }}
                >
                    Сделано на платформе
                </Typography>
                <img
                    src={'logotype.svg'}
                    alt="logotype"
                    onError={({currentTarget}) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "card_placeholder.png";
                    }}
                    style={{
                        objectFit: "contain",
                        marginBottom: "0.25rem",
                        marginLeft: "2rem",
                        height: "1.5rem",
                    }}
                />
            </Box>
        </Box>
    );
    return (
        <Drawer
            open={!isDrawerClosed}
            onClose={() => setIsDrawerClosed(true)}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                }
            }}
        >
            {DrawerList}
        </Drawer>
    );
}
