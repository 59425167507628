import {
    Button, Checkbox,
    Chip,
    createTheme, PaletteOptions,
    responsiveFontSizes, Skeleton,
    styled,
    TextareaAutosize,
    TextField, ToggleButton, ToggleButtonGroup,
    Toolbar
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as CheckboxCheckedSvg } from "./icons/checkbox-checked.svg";
import { ReactComponent as CheckboxUncheckedSvg } from "./icons/checkbox-unchecked.svg";
import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createThemeNoVars";
import {CssVarsTheme} from "@mui/material/styles/createThemeWithVars";
import * as Colors from "./colors";

const palette: PaletteOptions = {
    mode: "light",
    primary: {
        main: Colors.primary
    },
    secondary: {
        main: Colors.secondary
    },
    error: {
        main: Colors.error
    },
    success: {
        main: Colors.success
    },
    warning: {
        main: Colors.warning
    },
    info: {
        main: Colors.info
    }
};

const components: Components<Omit<Theme, 'components' | 'palette'> & CssVarsTheme> = {
    MuiTextField: {
        defaultProps: {
            variant: "standard",
        },
    },
    MuiCheckbox: {
        defaultProps: {
            icon: <CheckboxUncheckedSvg />,
            checkedIcon: <CheckboxCheckedSvg />,
        }
    },
    MuiSkeleton: {
        defaultProps: {
            variant: "rectangular",
        }
    }
};

export const themeMobile = responsiveFontSizes(createTheme({
    palette: palette,
    typography: {
        fontFamily: "Inter",
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            color: Colors.secondary
        },
        note: {
            fontSize: "0.625rem",
            fontWeight: 400,
            color: Colors.secondary
        },
        button: {
            fontSize: "1rem",
            fontWeight: 600
        },
        h1: {
            fontSize: "1rem",
            fontWeight: 600
        },
        h2: {
            fontSize: "0.875rem",
            fontWeight: 600
        },
        h3: {
            fontSize: "0.8125rem",
            fontWeight: 600
        },
        h4: {
            fontSize: "0.875rem",
            fontWeight: 500
        },
        body2: {
            ontSize: "0.875rem",
            fontWeight: 400
        }
    },
    shape: {
        borderRadius: 10,
    },
    components: components,
}));

export const themeDesktop = responsiveFontSizes(createTheme({
    palette: palette,
    typography: {
        fontFamily: "Inter",
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            color: Colors.secondary
        },
        note: {
            fontSize: "0.625rem",
            fontWeight: 400,
            color: Colors.secondary
        },
        button: {
            fontSize: "1rem",
            fontWeight: 600
        },
        h1: {
            fontSize: "1rem",
            fontWeight: 600
        },
        h2: {
            fontSize: "0.875rem",
            fontWeight: 600
        },
        h3: {
            fontSize: "0.8125rem",
            fontWeight: 600
        },
        body1: {
            ontSize: "0.875rem",
            fontWeight: 500
        },
        body2: {
            ontSize: "0.875rem",
            fontWeight: 400
        }
    },
    shape: {
        borderRadius: 10,
    },
    components: components,
}));

export const MuiChipCustom = styled(Chip)(() => ({
    height: "2rem",
    borderRadius: "0.625rem",
    "& .MuiChip-label": {
        fontSize: "1rem",
        fontWeight: 600,
    }
}));

export const MuiToolbarCustom = styled(Toolbar)(() => ({
    backgroundColor: "#ffffff",
    color: "#000000",
    justifyContent: "space-between",
}));

export const MuiButtonCustom = styled(Button)({
    textTransform: "none",
    fontSize: 16,
    fontWeight: 600,
    padding: "0.5rem 1rem"
});

export const MuiTextButtonCustom = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    fontWeight: 600,
    padding: "0.5rem 1rem",
    variant: "text",
    color: "#7C7C7C"
});

export const MuiSelectButtonCustom = styled(Button)({
    textTransform: "none",
    fontSize: 14,
    fontWeight: 600,
    padding: "0.4rem 1rem",
    borderRadius: "1.5rem",
});

export const MuiLoadingButtonCustom = styled(LoadingButton)({
    textTransform: "none",
    fontSize: 16,
    fontWeight: 600,
    padding: "0.5rem 1rem"
});

export const TextFieldCustom = styled(TextField)({
    fontSize: 14,
    fontWeight: 400,
    "& .MuiInput-underline:before": {
        borderBottomColor: "#7C7C7C"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#7C7C7C"
    },
    "& label": {
        "&.Mui-focused": {
            color: "#7C7C7C"
        },
        color: "#7C7C7C",
        fontWeight: 500,
    }
});

export const TextareaAutosizeCustom = styled(TextareaAutosize)(() => `
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    padding: 0.5rem 0.75rem;
    border-radius: 0.625rem;
    border: 1px solid #7C7C7C;
    resize: none;
    
    ::placeholder {
        font-weight: 500;
        color: #BEBEBE;
        opacity: 1; /* Firefox */
    }
    
    // firefox
    &:focus-visible {
    outline: 0;
  }
`);

export const ToggleButtonGroupCustom = styled(ToggleButtonGroup)({
    height: "3rem",
    borderRadius: "1.5rem",
    backgroundColor: "#F2F2F2",
    alignItems: "center"
});

export const ToggleButtonCustom = styled(ToggleButton)({
    height: "2.4rem",
    borderRadius: "1.2rem",
    color: Colors.secondary,
    backgroundColor: "#F2F2F2",
    "&.Mui-selected, &.Mui-selected:hover": {
        color: Colors.primary,
        backgroundColor: "#FFFFFF"
    },
    "&.MuiToggleButtonGroup-grouped": {
        borderRadius: "1.2rem !important",
        border: "0px !important"
    }
});

export const CheckboxCustom = styled(Checkbox)({
    "& .MuiSvgIcon-root": {
        fontSize: "1.5rem"
    },
    color: Colors.info,
    "&.Mui-checked": {
        color: Colors.info,
    }
});

export const SkeletonCustom = styled(Skeleton)({
    borderRadius: "1rem"
});
