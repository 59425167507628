import {createContext, Dispatch} from "react";
import {Order} from "./model/order";
import Company from "./model/company";
import {Card, GiverCard} from "./model/card";
import GlobalState from "./model/global-state";

export const GlobalContext = createContext<[GlobalState, Dispatch<GlobalState>]>(
    [new GlobalState(), () => {}]
);

export const DrawerContext = createContext<[boolean, Dispatch<boolean>]>(
    [true, () => {}]
);

export const TokenContext = createContext<[String | null, Dispatch<String>]>(
    [null, () => {}]
);

export const OrderContext = createContext<[Order, Dispatch<Order>]>(
    [new Order(), () => {}]
);

export const CompanyContext = createContext<[Company | null, Dispatch<Company>]>(
    [null, () => {}]
);

export const CardContext = createContext<[Card | null, Dispatch<Card>]>(
    [null, () => {}]
);

export const GiverCardContext = createContext<[GiverCard | null, Dispatch<GiverCard>]>(
    [null, () => {}]
);
