import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import Stack from "@mui/system/Stack";
import {BottomBar} from "../../widgets/bottom-bar";
import {LeftDrawer} from "../../widgets/drawer";
import {TopBar} from "../../widgets/top-bar";
import {Box, FormControlLabel, Typography, useTheme} from "@mui/material";
import {GlobalContext, OrderContext} from "../../context";
import TitleText from "../../widgets/title-text";
import {CheckboxCustom, ToggleButtonCustom, ToggleButtonGroupCustom} from "../../theme";
import {useNavigate} from "react-router-dom";
import {EmailInput, NameInput, PhoneInput} from "../../widgets/inputs";
import {isOrderValid} from "../../model/order";
import {FormState} from "../../model/form-state";

export function ContactsPage() {
    const theme = useTheme();
    const navigate = useNavigate();

    const [globalState, setGlobalState] = useContext(GlobalContext);
    const [order, setOrder] = useContext(OrderContext);

    const [formState, setFormState] = useState<FormState>(new FormState());

    const onDestinationChange = (_: any, value: boolean) => {
        const o = {
            ...order,
            toMyself: value
        }
        setOrder(o);
    };

    const onAnonymousChange = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
        const o = {
            ...order,
            anonymously: value
        }
        setOrder(o);
    };

    const onNextClick = () => {
        const r = isOrderValid(order);
        setFormState({
            sender: {
                name: r.sender.isNameValid,
                phone: r.sender.isPhoneValid,
                email: r.sender.isEmailValid
            },
            receiver: {
                ...formState.receiver,
                phone: r.receiver.isPhoneValid
            }
        });
        if (r.isAllValid()) {
            navigate("/order");
        }
    };

    useEffect(() => {
        const s = {
            ...globalState,
            isGiftSubjectSelected: true
        };
        setGlobalState(s);
    });

    return (
        <Box>
            <Stack bgcolor={theme.palette.background.paper}>
                <LeftDrawer />
                <TopBar />
                <Stack
                    height="100%"
                    textAlign="center"
                    sx={{
                        display: "flex"
                    }}
                >
                    <TitleText
                        title="Кому отправить?"
                        sx={{
                            mt: '0.875rem',
                            mx: '2rem',
                            alignSelf: 'start'
                        }}
                    />
                    <ToggleButtonGroupCustom
                        color="primary"
                        value={order.toMyself}
                        exclusive
                        onChange={onDestinationChange}
                        sx={{
                            mt: "1rem",
                            mx: "2rem"
                        }}
                    >
                        <ToggleButtonCustom
                            value={false}
                            fullWidth
                            size="small"
                            sx={{
                                mr: "0.3rem",
                                ml: "0.3rem"
                            }}
                        >
                            В подарок
                        </ToggleButtonCustom>
                        <ToggleButtonCustom
                            value={true}
                            fullWidth
                            size="small"
                            sx={{
                                mr: "0.3rem"
                            }}
                        >
                            Себе
                        </ToggleButtonCustom>
                    </ToggleButtonGroupCustom>
                    {
                        order.toMyself ? null : <NameInput
                            isSender={false}
                            sx={{
                                mt: "1rem",
                                mx: "2rem"
                            }}
                        />
                    }
                    {
                        order.toMyself ? null : <PhoneInput
                            isSender={false}
                            isError={!formState.receiver.phone}
                            onErrorClean={() => {
                                formState.receiver.phone = true;
                                setFormState(formState);
                            }}
                            style={{
                                marginTop: "0.5rem",
                                marginLeft: "2rem",
                                marginRight: "2rem"
                            }}
                        />
                    }
                    {
                        order.toMyself ? null : <EmailInput
                            isSender={false}
                            isError={!formState.receiver.email}
                            onErrorClean={() => {
                                formState.receiver.email = true;
                                setFormState(formState);
                            }}
                            sx={{
                                mt: "0.5rem",
                                mx: "2rem"
                            }}
                        />
                    }
                    {
                        order.toMyself ? null : <Box
                            textAlign="left"
                            sx={{
                                mt: "1.5rem",
                                mx: "2rem"
                            }}
                        >
                            ОТ КОГО?
                        </Box>
                    }
                    {
                        order.toMyself ? null : <FormControlLabel
                            sx={{
                                mr: "2rem",
                                ml: "1.3rem",
                                mt: "0.5rem",
                                fontSize: "0.8125rem",
                                fontWeight: 600,
                                color: "#2A94FF"
                            }}
                            control={
                                <CheckboxCustom
                                    checked={order.anonymously}
                                    onChange={onAnonymousChange}
                                />
                            }
                            label={
                                <Typography variant="h3">
                                    Отправить анонимно
                                </Typography>
                            }
                        />
                    }
                    <NameInput
                        isSender
                        isError={!formState.sender.name}
                        onErrorClean={() => {
                            formState.sender.name = true;
                            setFormState(formState);
                        }}
                        sx={{
                            mt: "1rem",
                            mx: "2rem"
                        }}
                    />
                    <PhoneInput
                        isSender={true}
                        isError={!formState.sender.phone}
                        onErrorClean={() => {
                            formState.sender.phone = true;
                            setFormState(formState);
                        }}
                        style={{
                            marginTop: "0.5rem",
                            marginLeft: "2rem",
                            marginRight: "2rem"
                        }}
                    />
                    <EmailInput
                        isSender={true}
                        isError={!formState.sender.email}
                        onErrorClean={() => {
                            formState.sender.email = true;
                            setFormState(formState);
                        }}
                        sx={{
                            mt: "0.5rem",
                            mx: "2rem",
                            mb: "5rem"
                        }}
                    />
                </Stack>
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                zIndex={theme.zIndex.drawer + 2}
            >
                <BottomBar
                    progress={100}
                    caption="3/3"
                    onClick={onNextClick}
                />
            </Box>
        </Box>
    );
}
