import Grid from "@mui/material/Grid2";
import Stack from "@mui/system/Stack";
import {Box, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {CardContext} from "../../context";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {OrderRow} from "../../widgets/order-row";
import formatPhone from "../../utils/phone";
import moment from "moment";
import {NotificationSnackBar} from "../../widgets/snackbar";

export function SuccessPage() {
    const [card] = useContext(CardContext);
    const [toShowNotification, setToShowNotification] = useState<boolean>(true);

    let burnDate = new Date();
    if (card?.expiration_date !== null) {
        burnDate = new Date(0);
        burnDate.setUTCSeconds(card!.expiration_date);
    }

    return <Grid
        container
        spacing={3}
    >
        <Grid size={8}>
            <Stack
                sx={{
                    borderRadius: "2rem",
                    backgroundColor: "#F8F8F8",
                    py: "2rem",
                    px: "3.75rem",
                }}
            >
                {
                    card?.getter.name ? <OrderRow
                        title="Получатель"
                        text={card?.getter.name}
                        mt="0.5rem"
                    /> : null
                }
                {
                    card?.getter.phone ? <OrderRow
                        title="На номер"
                        text={formatPhone(card?.getter.phone)}
                        mt="0.5rem"
                    /> : null
                }
                {
                    card?.getter.email ? <OrderRow
                        title="E-mail"
                        text={card?.getter.email}
                        mt="0.5rem"
                    /> : null
                }
                {
                    card?.message ? <OrderRow
                        title="Текст пожелания"
                        text={card?.message}
                        direction="column"
                        mt="1rem"
                    /> : null
                }
                {
                    card?.giver.name || card?.giver.phone || card?.giver.email ? <Box mt="1rem" /> : null
                }
                {
                    card?.giver.name ? <OrderRow
                        title="От"
                        text={card?.giver.name}
                        mt="0.5rem"
                    /> : null
                }
                {
                    card?.giver.phone ? <OrderRow
                        title="Телефон"
                        text={formatPhone(card?.giver.phone)}
                        mt="0.5rem"
                    /> : null
                }
                {
                    card?.giver.email ? <OrderRow
                        title="E-mail"
                        text={card?.giver.email}
                        mt="0.5rem"
                    /> : null
                }
                <Box mt="1rem" />
                <OrderRow
                    title="Срок действия"
                    text={moment(burnDate).format('DD.MM.YYYY')}
                    mt="0.5rem"
                />
                <OrderRow
                    title="Номинал"
                    text={`${card?.nominal.toString()} ₽`}
                    mt="0.5rem"
                />
            </Stack>
        </Grid>
        <Grid size={4}>
            <Stack>
                <Box
                    position="relative"
                >
                    <img
                        src={card?.image!}
                        alt={"card"}
                        style={{
                            borderRadius: "1rem",
                            aspectRatio: 1.586,
                            width: "100%"
                        }}
                    />
                    <Box
                        position="absolute"
                        bottom="1rem"
                        right="0%"
                        sx={{
                            backgroundColor: "#474748",
                            borderBottomLeftRadius: "0.75rem",
                            borderTopLeftRadius: "0.75rem",
                            px: "1.5rem",
                            py: "0.5rem"
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "1.25rem",
                                fontWeight: 600,
                                color: "white",
                            }}
                        >
                            {`${card?.nominal!} ₽`}
                        </Typography>
                    </Box>
                </Box>
            </Stack>
        </Grid>
        <NotificationSnackBar
            type={"success"}
            message={"Карта успешно оплачена"}
            open={toShowNotification}
            onClose={() => setToShowNotification(false)}
        />
    </Grid>;
}
