import {createMemoryRouter, RouterProvider} from "react-router-dom";
import {MainPage} from "./main";
import {WishesPage} from "./wishes";
import {ContactsPage} from "./contacts";
import {OrderPage} from "./order";
import {ImageCropperPage} from "./image-cropper";
import {SuccessPage} from "./success";
import React, {useContext, useEffect, useState} from "react";
import {Box, Container, useTheme} from "@mui/material";
import {CardContext, DrawerContext} from "../../context";
import Loading from "../../widgets/loading";

const router = createMemoryRouter([
    {
        path: "/",
        element: <MainPage />
    },
    {
        path: "/wish",
        element: <WishesPage />
    },
    {
        path: "/contacts",
        element: <ContactsPage />
    },
    {
        path: "/order",
        element: <OrderPage />
    },
    {
        path: "/image_cropper",
        element: <ImageCropperPage />
    },
    {
        path: "/success",
        element: <SuccessPage />
    }
])

export function MobileRootPage() {
    const theme = useTheme();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDrawerClosed, setIsDrawerClosed] = useState<boolean>(true);
    const [card] = useContext(CardContext);

    useEffect(() => {
        const fetchData = async () => {
            if (card == null) {
                return;
            }
            if (card.activated !== null) {
                await router.navigate("/success");
            } else {
                await router.navigate("/wish");
                await router.navigate("/contacts");
                await router.navigate("/order");
            }
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    });

    if (isLoading) {
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Container
                disableGutters
                maxWidth="md"
            >
                <Box
                    minHeight="100vh"
                    bgcolor={theme.palette.background.paper}
                >
                    <DrawerContext.Provider
                        value={[isDrawerClosed, setIsDrawerClosed]}
                    >
                        <RouterProvider router={router}/>
                    </DrawerContext.Provider>
                </Box>
            </Container>
        </React.Fragment>
    );
}
