import {Box, IconButton, Typography} from "@mui/material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Stack from "@mui/system/Stack";
import Grid from "@mui/material/Grid2";
import React, {useContext} from "react";
import {GlobalContext, OrderContext} from "../../context";
import {EmailInput, NameInput, PhoneInput} from "../../widgets/inputs";
import {FormState} from "../../model/form-state";

interface GiftFormProps {
    formState: FormState;
    onChange: (s: FormState) => void;
}

export default function GiftForm({formState, onChange} : GiftFormProps) {
    const [order] = useContext(OrderContext);
    const [globalState, setGlobalState] = useContext(GlobalContext);

    return <Box>
        <IconButton
            sx={{
                ml: "1.25rem",
                mt: "1.5rem",
                color: "#474748"
            }}
            onClick={() => {
                const s = {
                    ...globalState,
                    isGiftSubjectSelected: false
                };
                setGlobalState(s);
            }}
        >
            <ArrowBackIosRoundedIcon />
        </IconButton>
        <Grid container wrap="nowrap"
              sx={{
                  mx: "3.75rem",
                  pb: "2.5rem"
              }}
        >
            <Stack>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                        color: "#474748",
                        alignSelf: "start"
                    }}
                >
                    {order.toMyself ? "Себе" : "Кому"}
                </Typography>
                <NameInput
                    isSender={order.toMyself}
                    isError={order.toMyself ? !formState.sender.name : false}
                    onErrorClean={order.toMyself ? () => {
                        formState.sender.name = true;
                        onChange(formState);
                    } : null}
                    sx={{
                        mt: "1rem",
                    }}
                />
                <PhoneInput
                    isSender={order.toMyself}
                    isError={order.toMyself ? !formState.sender.phone : !formState.receiver.phone}
                    onErrorClean={
                        () => order.toMyself ? () => {
                            formState.sender.phone = true;
                            onChange(formState);
                        } : () => {
                            formState.receiver.phone = true;
                            onChange(formState);
                        }
                    }
                    style={{
                        marginTop: "1rem",
                    }}
                />
                <EmailInput
                    isSender={order.toMyself}
                    isError={order.toMyself ? !formState.sender.email : false}
                    onErrorClean={
                        () => order.toMyself ? () => {
                            formState.sender.email = true;
                            onChange(formState);
                        } : null
                    }
                    sx={{
                        mt: "1rem",
                    }}
                />
            </Stack>
            {
                order.toMyself ? null : <Stack
                    sx={{
                        mx: "3rem"
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                            color: "#474748",
                            alignSelf: "start"
                        }}
                    >
                        {"От кого"}
                    </Typography>
                    <NameInput
                        isSender={true}
                        isError={!formState.sender.name}
                        onErrorClean={() => {
                            formState.sender.name = true;
                            onChange(formState);
                        }}
                        sx={{
                            mt: "1rem",
                        }}
                    />
                    <PhoneInput
                        isSender={true}
                        isError={!formState.sender.phone}
                        onErrorClean={() => {
                            formState.sender.phone = true;
                            onChange(formState);
                        }}
                        style={{
                            marginTop: "1rem",
                        }}
                    />
                    <EmailInput
                        isSender={true}
                        isError={!formState.sender.email}
                        onErrorClean={() => {
                            formState.sender.email = true;
                            onChange(formState);
                        }}
                        sx={{
                            mt: "1rem",
                        }}
                    />
                </Stack>
            }
        </Grid>
    </Box>;
}
