import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {MuiSelectButtonCustom} from "../../theme";
import Stack from "@mui/system/Stack";
import React, {useContext} from "react";
import {CompanyContext, GlobalContext, OrderContext} from "../../context";

export default function GiftSubjectSelector() {
    const [company] = useContext(CompanyContext);
    const [order, setOrder] = useContext(OrderContext);
    const [globalState, setGlobalState] = useContext(GlobalContext);
    return <Stack
        sx={{
            py: "2rem",
            px: "3.75rem",
        }}
    >
        <Typography
            sx={{
                fontFamily: "Inter",
                fontSize: "1rem",
                fontWeight: 600,
                color: "#474748",
                alignSelf: "center"
            }}
        >
            Выберите кому отправить
        </Typography>
        <Grid container wrap="nowrap"
              sx={{
                  mt: "0.875rem",
                  mx: "1.5rem",
                  alignSelf: "center"
              }}
        >
            <MuiSelectButtonCustom
                variant="contained"
                disabled={company === null}
                onClick={() => {
                    const s = {
                        ...globalState,
                        isGiftSubjectSelected: true
                    };
                    setGlobalState(s);
                    const o = {
                        ...order,
                        toMyself: true
                    };
                    setOrder(o);
                }}
            >
                Себе
            </MuiSelectButtonCustom>
            <MuiSelectButtonCustom
                variant="contained"
                disabled={company === null}
                sx={{
                    ml: "1rem"
                }}
                onClick={() => {
                    const s = {
                        ...globalState,
                        isGiftSubjectSelected: true
                    };
                    setGlobalState(s);
                    const o = {
                        ...order,
                        toMyself: false
                    };
                    setOrder(o);
                }}
            >
                В подарок
            </MuiSelectButtonCustom>
        </Grid>
    </Stack>;
}
