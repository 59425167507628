import "./App.css";
import {GiverCardContext, GlobalContext, TokenContext} from "./context";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {GiverCard} from "./model/card";
import MediaQuery from 'react-responsive'
import GlobalState from "./model/global-state";
import Loading from "./widgets/loading";
import NotFound from "./widgets/NotFound";
import {MobileReceiverRootPage} from "./pages/mobile/ReceiverRoot";

// https://card.carddevgroup.ru/?verify_token=OSMjIzHTJQ67o4QdIdJ-WC1uyHbKbYPDY4cfGEYtd3DMzDsheg==

export function App() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const hrefSplit = window.location.href.split("/");
    const [globalState, setGlobalState] = useState<GlobalState>(new GlobalState());
    const token_ = hrefSplit.length > 1 ? hrefSplit.slice(-1)[0] : null;
    const [token, setToken] = useState<String | null>(token_);
    const [card, setCard] = useState<GiverCard | null>(null);

    useEffect(() => {
        const BASE_URL = process.env.REACT_APP_API_URL;
        const fetchData = async () => {
            // setCard(defaultGiverCard);
            const card: GiverCard = (
                await axios.get(`${BASE_URL}/public/cards/getter/info/${token_}`)
            ).data;
            setCard(card);
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    }, [token_, setCard, setIsLoading]);

    if (!token_) {
        return <NotFound />;
    }

    if (isLoading) {
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        );
    }

    return (
        <GlobalContext.Provider
            value={[globalState, setGlobalState]}
        >
            <TokenContext.Provider
                value={[token, setToken]}
            >
                <GiverCardContext.Provider
                    value={[card, setCard]}
                >
                    <div>
                        <MediaQuery minWidth={800}>
                            <MobileReceiverRootPage />
                        </MediaQuery>
                        <MediaQuery maxWidth={800}>
                            <MobileReceiverRootPage />
                        </MediaQuery>
                    </div>
                </GiverCardContext.Provider>
            </TokenContext.Provider>
        </GlobalContext.Provider>
    );
}
