import {Nominal} from "./company";
import {CardPerson} from "./card";
import {emailRegexp, phoneRegexp} from "../utils/regexp";
import axios from "axios";
import imageToBase64 from "../utils/image";

export class PersonValidation {
    isPhoneValid: boolean =  true;
    isEmailValid: boolean =  true;
    isNameValid: boolean =  true;

    isAllValid(): boolean {
        return this.isPhoneValid && this.isEmailValid && this.isNameValid;
    }
}

export class OrderValidation {
    sender: PersonValidation = new PersonValidation();
    receiver: PersonValidation = new PersonValidation();
    isNominalValid: boolean = true;

    isAllValid(): boolean {
        return this.sender.isAllValid() && this.receiver.isAllValid() && this.isNominalValid;
    }

    onlyNominalNotValid(): boolean {
        return this.sender.isAllValid() && this.receiver.isAllValid() && !this.isNominalValid;
    }
}

export class Order {
    image: string | null = null;
    nominal: Nominal | null = null;
    wishes: string = "";
    toMyself: boolean = false;
    anonymously: boolean = false;
    sender: CardPerson = new CardPerson();
    receiver: CardPerson = new CardPerson();
    addedImages: string[] = []
}

export function isOrderValid(order: Order): OrderValidation {
    const r = new OrderValidation();
    if (!phoneRegexp.test(order.sender.phone)) {
        r.sender.isPhoneValid = false;
    }
    if (!emailRegexp.test(order.sender.email)) {
        r.sender.isEmailValid = false;
    }
    if (order.sender.name.length < 2) {
        r.sender.isNameValid = false;
    }
    if (!order.toMyself) {
        if (!phoneRegexp.test(order.receiver.phone)) {
            r.receiver.isPhoneValid = false;
        }
        if (order.receiver.email && !emailRegexp.test(order.receiver.email)) {
            r.receiver.isEmailValid = false;
        }
    }
    if (!order.nominal) {
        r.isNominalValid = false;
    }
    return r;
}

export async function makeOrder(token: String, order: Order): Promise<String | null> {
    const BASE_URL = process.env.REACT_APP_API_URL;
    let image = order.image;
    if (image === null) {
        return null;
    }
    if ((new URL(order.image!)).protocol === "blob:") {
        try {
            const blob = await (await fetch(order.image!)).blob();
            image = await imageToBase64(blob);
            image = image.substring(image.indexOf(',') + 1)
        } catch (_) {
            return null;
        }
    }
    try {
        const r = await axios.post(
            `${BASE_URL}/public/cards/${token}`,
            {
                nominal_id: order.nominal?.id,
                message: order.wishes,
                image: image,
                getter: {
                    name: order.toMyself ? order.sender.name : order.receiver.name,
                    phone: order.toMyself ? order.sender.phone : order.receiver.phone,
                    email: order.toMyself ? order.sender.email : order.receiver.email
                },
                giver: {
                    name: order.sender.name,
                    phone: order.sender.phone ? order.sender.phone : "",
                    email: order.sender.email
                },
                giver_anonymous: order.anonymously
            }
        );
        return r.data.url;
    } catch (_) {}
    return null;
}
