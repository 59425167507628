import {Box, Container, Typography, useTheme} from "@mui/material";
import React from "react";

export default function NotFound() {
    const theme = useTheme();
    return <React.Fragment>
        <Container
            maxWidth="lg"
            sx={{
                backgroundColor: theme.palette.background.paper
            }}
        >
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h1"
                    color={theme.palette.primary.main}
                >
                    Not found
                </Typography>
            </Box>
        </Container>
    </React.Fragment>;
}
