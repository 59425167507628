import React, {useContext, useRef, useState} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import Stack from "@mui/system/Stack";
import AddIcon from "@mui/icons-material/Add";
import {MuiButtonCustom, SkeletonCustom} from "../../theme";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {BottomBar} from "../../widgets/bottom-bar";
import {TopBar} from "../../widgets/top-bar";
import {LeftDrawer} from "../../widgets/drawer";
import TitleText from "../../widgets/title-text";
import {CompanyContext, OrderContext} from "../../context";
import {useNavigate} from "react-router-dom";
import type {Swiper as SwiperClass} from "swiper/types";
import {NominalSelector} from "../../widgets/nominal-selector";
import {NotificationSnackBar} from "../../widgets/snackbar";

export function MainPage() {
    const theme = useTheme();
    const navigate = useNavigate();

    const [order, setOrder] = useContext(OrderContext);
    const [company] = useContext(CompanyContext);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [toShowNotification, setToShowNotification] = useState<boolean>(false);

    let images = company?.images.concat(order.addedImages) ?? [];
    return (
        <Box>
            <Stack bgcolor={theme.palette.background.paper}>
                <LeftDrawer/>
                <TopBar main/>
                <TitleText
                    title="Электронная подарочная карта"
                    sx={{
                        mt: '0.875rem',
                        mx: '2rem',
                        alignSelf: 'start'
                    }}
                />
                {
                    images.length > 1 ? <Box mt="1rem">
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            spaceBetween={0}
                            centeredSlides={true}
                            centeredSlidesBounds={images.length > 3}
                            centerInsufficientSlides={true}
                            loop={images.length > 2}
                            slidesPerView={1.25}
                            initialSlide={order.image ? images.indexOf(order.image) : 0}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 20,
                                depth: 0,
                                modifier: 1,
                                slideShadows: true,
                                scale: 0.8
                            }}
                            pagination={{el: ".swiper-pagination", clickable: true, dynamicBullets: true,}}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className="swiper-images"
                            onSwiper={setSwiper}
                            onSlideChange={() => {
                                if (!swiper?.realIndex) {
                                    return;
                                }
                                const o = {
                                    ...order,
                                    image: images[swiper?.realIndex]
                                };
                                setOrder(o);
                            }}
                        >
                            {
                                images.map((image) => (
                                    <SwiperSlide
                                        key={image}
                                    >
                                        <img
                                            src={image}
                                            alt="slide"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src="card_placeholder.png";
                                            }}
                                        />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Box> : images.length > 0 ? <img
                        src={images[0]}
                        alt={"card"}
                        style={{
                            marginTop: "1rem",
                            marginLeft: "2rem",
                            marginRight: "2rem",
                            borderRadius: "1rem",
                            aspectRatio: 1.586,
                            width: "80%",
                            alignSelf: "center"
                        }}
                    /> : <Box
                        sx={{
                            mx: "2rem",
                            mt: "1rem",
                            width: "80%",
                            aspectRatio: 1.586,
                            alignSelf: "center"
                        }}
                    >
                        <SkeletonCustom
                            sx={{
                                height: "100%"
                            }}
                        />
                    </Box>
                }
                {
                    images.length > 1 ? <Box mt="0.5rem">
                        <div className="slider-controller">
                            <div className="swiper-pagination"></div>
                        </div>
                    </Box> : null
                }
                <Box
                    sx={{
                        mt: "0.7rem",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <MuiButtonCustom
                        variant="contained"
                        startIcon={<AddIcon/>}
                        disabled={company === null}
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}
                    >
                        Мой дизайн
                    </MuiButtonCustom>
                </Box>
                <Box
                    sx={{
                        mt: "1.75rem",
                        mx: "2rem"
                    }}
                >
                    <Typography
                        variant="h2"
                        color={theme.palette.primary.main}
                    >
                        Выберите номинал
                    </Typography>
                </Box>
                <NominalSelector
                    sx={{
                        mt: "1rem",
                        mb: "5rem"
                    }}
                />
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                zIndex={theme.zIndex.drawer + 2}
            >
                <BottomBar
                    progress={33}
                    caption="1/3"
                    route={order.nominal && order.image ? "/wish" : null}
                />
            </Box>
            <label>
                <input
                    hidden
                    ref={fileInputRef}
                    type='file'
                    accept=".jpg, .jpeg, .png"
                    // capture="environment"
                    onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                            const url = URL.createObjectURL(event.target.files[0]);
                            const img = new Image();
                            img.src = url;
                            img.onload = () => {
                                if (img.width < 1024 || img.height < 1024 / 1.586) {
                                    setToShowNotification(true);
                                    return;
                                }
                                navigate(
                                    "/image_cropper",
                                    { state: { imageUrl: url } }
                                );
                            };
                        }
                    }}
                />
            </label>
            <NotificationSnackBar
                type={"error"}
                open={toShowNotification}
                onClose={() => setToShowNotification(false)}
                message={"Размер изображения должен быть не менее 1024x645"}
            />
        </Box>
    );
}
