import {IconButton, Slide, Snackbar, useTheme} from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type NotificationType = 'error' | 'success' | 'warning' | 'info';

interface NotificationSnackBarProps {
    type: NotificationType;
    open: boolean;
    onClose?: (() => void) | null;
    message: string;
}

export function NotificationSnackBar({
    type, open, message, onClose
}: NotificationSnackBarProps) {
    const theme = useTheme();
    const getBgColor = (type: NotificationType) => {
        switch (type) {
            case "success": return theme.palette.success.main;
            case "warning": return theme.palette.warning.main;
            case "info": return theme.palette.primary.main;
            case "error": return theme.palette.error.main;
        }
    }
    const snackBarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                    if (onClose) {
                        onClose();
                    }
                }}
            >
                <CloseRoundedIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return <Snackbar
        key={Math.random()}
        ContentProps={{
            sx: {
                backgroundColor: getBgColor(type),
                color: "#fff",
                fontWeight: "bold",
                py: "1rem",
            }
        }}
        action={onClose ? snackBarAction : undefined}
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        open={open}
        onClose={() => {
            if (onClose) {
                onClose();
            }
        }}
        message={message}
        TransitionComponent={props => <Slide {...props} direction="down" />}
    />;
}
