import React, {useContext, useEffect, useState} from "react";
import {Box, Container, Typography, useTheme} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {MuiTextButtonCustom} from "../../theme";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {createMemoryRouter, RouterProvider} from "react-router-dom";
import {MainPage} from "./main";
import {ImageCropperPage} from "./image-cropper";
import Loading from "../../widgets/loading";
import {CardContext, CompanyContext} from "../../context";
import {SuccessPage} from "./success";
import formatPhone from "../../utils/phone";
import {OfferAgreementPage} from "./offer_agreement";
import {RulesPage} from "./rules";

const router = createMemoryRouter([
    {
        path: "/",
        element: <MainPage />
    },
    {
        path: "/image_cropper",
        element: <ImageCropperPage />
    },
    {
        path: "/success",
        element: <SuccessPage />
    },
    {
        path: "/offer_agreement",
        element: <OfferAgreementPage />
    },
    {
        path: "/rules",
        element: <RulesPage />
    }
])

export function DesktopRootPage() {
    const theme = useTheme();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [card] = useContext(CardContext);
    const [company] = useContext(CompanyContext);

    useEffect(() => {
        const fetchData = async () => {
            if (card == null) {
                return;
            }
            if (card.activated !== null) {
                await router.navigate("/success");
            }
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    });

    if (isLoading) {
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Container
                disableGutters
                maxWidth="lg"
            >
                <Box
                    minHeight="100vh"
                    bgcolor={theme.palette.background.paper}
                >
                    <Box
                        sx={{
                            pt: "4.5rem",
                            px: "1.5rem",
                            pb: "4rem"
                        }}
                    >
                        <RouterProvider router={router}/>
                    </Box>
                    <Grid container
                          wrap="nowrap"
                          sx={{
                              px: "1.5rem",
                              py: "0.5rem",
                              height: "4.5rem",
                              position: "fixed",
                              top: "0px",
                              bgcolor: "white",
                              alignItems: "center",
                              width: "100%",
                              maxWidth: "lg"
                          }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#F8F8F8",
                                width: 182,
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            onClick={async () => {
                                while (router.state.location.pathname !== "/") {
                                    await router.navigate(-1);
                                }
                            }}
                        >
                            <img
                                src={company?.logotype ?? 'logotype.svg'}
                                alt="logotype"
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "card_placeholder.png";
                                }}
                                // height={"3.125rem"}
                                // width={"11.375rem"}
                                height={48}
                                width={174}
                                style={{
                                    objectFit: "contain",
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "1.25rem",
                                fontWeight: 500,
                                color: "#474748",
                                ml: "2rem",
                                alignSelf: "center"
                            }}
                        >
                            Электронная подарочная карта
                        </Typography>
                        <MuiTextButtonCustom
                            sx={{
                                ml: "auto",
                                visibility: "hidden"
                            }}
                        >
                            ПРОВЕРКА БАЛАНСА
                        </MuiTextButtonCustom>
                        <MuiTextButtonCustom
                            sx={{
                                mx: "2rem"
                            }}
                        >
                            КАК ЭТО РАБОТАЕТ
                        </MuiTextButtonCustom>
                    </Grid>
                    <Grid container
                          wrap="nowrap"
                          width="100%"
                          sx={{
                              position: "fixed",
                              bottom: "0px",
                              py: "0.5rem",
                              px: "1.5rem",
                              backgroundColor: "white",
                              maxWidth: "lg",
                              alignItems: "center"
                          }}
                    >
                        <img
                            src={"logotype.svg"}
                            alt="gifted_logotype"
                        />
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontWeight: 500,
                                color: "#474748",
                                ml: "3rem",
                                alignSelf: "center"
                            }}
                        >
                            {formatPhone(company?.support_phone)}
                        </Typography>
                        <MuiTextButtonCustom
                            sx={{
                                ml: "auto"
                            }}
                            onClick={async () => {
                                await router.navigate("/rules")
                            }}
                        >
                            ПРАВИЛА
                        </MuiTextButtonCustom>
                        <MuiTextButtonCustom
                            sx={{
                                mx: "2rem"
                            }}
                            onClick={async () => {
                                await router.navigate("/offer_agreement")
                            }}
                        >
                            ОФЕРТА
                        </MuiTextButtonCustom>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
}
