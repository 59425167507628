const formatPhone = (phone: string | null | undefined): string => {
    if (!phone) {
        return "";
    }
    if (phone.length === 0) {
        return "";
    }
    const code = phone[0];
    phone = phone.substring(1);
    return `+${code} (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 8)}-${phone.substring(8)}`
}

export default formatPhone;
