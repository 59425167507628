import React, {useContext, useState} from "react";
import {Box, Container, Typography, useTheme} from "@mui/material";
import {GiverCardContext} from "../../context";
import Loading from "../../widgets/loading";
import Stack from "@mui/system/Stack";
import {TopBarSimple} from "../../widgets/top-bar";
import {SkeletonCustom} from "../../theme";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import * as Colors from "../../colors";
import {OrderRow} from "../../widgets/order-row";
import formatPhone from "../../utils/phone";
import moment from "moment";

export function MobileReceiverRootPage() {
    const theme = useTheme();

    const [isLoading] = useState<boolean>(false);
    const [card] = useContext(GiverCardContext);

    if (isLoading) {
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        );
    }

    const isQrCode = card?.code_type === "QRCODE";
    let burnDate = new Date();
    if (card?.expiration_date !== null) {
        burnDate = new Date(0);
        burnDate.setUTCSeconds(card!.expiration_date);
    }

    return (
        <React.Fragment>
            <Container
                disableGutters
                maxWidth="md"
            >
                <Box
                    minHeight="100vh"
                    bgcolor={theme.palette.background.paper}
                >
                    <Box>
                        <Stack bgcolor={theme.palette.background.paper}>
                            <TopBarSimple
                                logotype={card?.logotype}
                            />
                            <Typography
                                variant="h3"
                                sx={{
                                    mt: '1rem',
                                    mx: '2rem',
                                    alignSelf: 'start',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                ЭЛЕКТРОННАЯ ПОДАРОЧНАЯ КАРТА
                            </Typography>
                            {
                                card?.image ? <img
                                    src={card?.image}
                                    alt={"card"}
                                    style={{
                                        marginTop: "1.5rem",
                                        marginLeft: "2rem",
                                        marginRight: "2rem",
                                        borderRadius: "1rem",
                                        aspectRatio: 1.586,
                                        width: "80%",
                                        alignSelf: "center"
                                    }}
                                /> : <Box
                                    sx={{
                                        mx: "2rem",
                                        mt: "1.5rem",
                                        width: "80%",
                                        aspectRatio: 1.586,
                                        alignSelf: "center"
                                    }}
                                >
                                    <SkeletonCustom
                                        sx={{
                                            height: "100%"
                                        }}
                                    />
                                </Box>
                            }
                            <Typography
                                variant="h2"
                                sx={{
                                    mt: '1.2rem',
                                    mx: '2rem',
                                    textAlign: 'center',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {`БАЛАНС ${card?.balance} ₽`}
                            </Typography>
                            <Typography
                                variant="h2"
                                sx={{
                                    mt: '1rem',
                                    mx: '2rem',
                                    textAlign: 'center',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {`№ ${card?.full_number}`}
                            </Typography>
                            {
                                card?.full_number ? <Box
                                    sx={{
                                        mt: '1.5rem',
                                        mx: '2rem',
                                        alignSelf: 'center',
                                        maxWidth: isQrCode ? "10rem" : null,
                                        height: "auto",
                                    }}
                                >
                                    {
                                        isQrCode ? <QRCode
                                            value={card?.full_number ?? ""}
                                            style={{
                                                height: "auto",
                                                width: "100%",
                                                maxWidth: "100%"
                                            }}
                                            fgColor={Colors.primaryLight}
                                        /> : <Barcode
                                            value={card?.full_number ?? ""}
                                            height={100}
                                            displayValue={false}
                                            lineColor={Colors.primaryLight}
                                        />
                                    }
                                </Box> : <Box
                                    sx={{
                                        mx: "2rem",
                                        mt: "1.5rem",
                                        width: "40%",
                                        aspectRatio: 1,
                                        alignSelf: "center"
                                    }}
                                >
                                    <SkeletonCustom
                                        sx={{
                                            height: "100%"
                                        }}
                                    />
                                </Box>
                            }
                            <Typography
                                variant="body2"
                                sx={{
                                    mt: '1.5rem',
                                    mx: '2rem',
                                    textAlign: 'center',
                                    color: Colors.primaryLight,
                                }}
                            >
                                {`Чтобы воспользоваться картой покажите ваш ${isQrCode ? "QR-код" : "штрихкод"} кассиру`}
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{
                                    mt: '1.5rem',
                                    mx: '2rem',
                                    alignSelf: 'start',
                                    color: Colors.primary,
                                }}
                            >
                                ПОЖЕЛАНИЕ
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    mt: '1rem',
                                    mx: '2rem',
                                    alignSelf: 'start',
                                    color: Colors.primary,
                                }}
                            >
                                {card?.message}
                            </Typography>
                            {
                                card?.giver.phone ? <Typography
                                    variant="h3"
                                    sx={{
                                        mt: '1rem',
                                        mx: '2rem',
                                        alignSelf: 'start',
                                        color: Colors.primary,
                                    }}
                                >
                                    ОТ КОГО
                                </Typography> : null
                            }
                            {
                                card?.giver.name ? <OrderRow
                                    sx={{
                                        mt: '1rem',
                                        mx: '2rem',
                                    }}
                                    title={"От"}
                                    text={card?.giver.name ?? ""}
                                /> : null
                            }
                            {
                                card?.giver.phone ? <OrderRow
                                    sx={{
                                        mx: '2rem',
                                    }}
                                    title={"Телефон"}
                                    text={formatPhone(card!.giver.phone)}
                                /> : null
                            }
                            {
                                card?.giver.email ? <OrderRow
                                    sx={{
                                        mx: '2rem',
                                    }}
                                    title={"E-mail"}
                                    text={card!.giver.email}
                                /> : null
                            }
                            <Typography
                                variant="h3"
                                sx={{
                                    mt: '1rem',
                                    mx: '2rem',
                                    alignSelf: 'start',
                                    color: Colors.primary,
                                }}
                            >
                                О КАРТЕ
                            </Typography>
                            <OrderRow
                                sx={{
                                    mt: '1rem',
                                    mx: '2rem',
                                }}
                                title={"Срок действия"}
                                text={moment(burnDate).format('DD.MM.YYYY')}
                            />
                            <OrderRow
                                sx={{
                                    mx: '2rem',
                                }}
                                title={"Номинал"}
                                text={`${card?.nominal} ₽`}
                            />
                            <Box
                                height={"4rem"}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
}
