export class CardPerson {
    name: string = "";
    phone: string = "";
    email: string = "";
}

export class Card {
    id!: number;
    nominal!: number;
    balance!: number;
    created!: number;
    activated?: number;
    expiration_date!: number;
    image!: string;
    number!: number;
    full_number!: string;
    message!: string;
    giver!: CardPerson;
    getter!: CardPerson;
}

type CodeType = "QRCODE" | "BARCODE";

export class GiverCard {
    nominal!: number;
    message!: string;
    balance!: number;
    image!: string;
    expiration_date!: number;
    giver!: CardPerson;
    getter!: CardPerson;
    company_name!: string;
    logotype!: string;
    support_phone!: string;
    code_type!: CodeType;
    full_number!: string;
}
