import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.css';
import * as GiverApp from './GiverApp';
import * as ReceiverApp from './ReceiverApp';
import reportWebVitals from './reportWebVitals';
import {themeDesktop, themeMobile} from "./theme";
import {ThemeProvider} from "@mui/material";
import MediaQuery from "react-responsive";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const side = process.env.REACT_APP_GIFTED_SIDE;
root.render(
  <React.StrictMode>
    <div>
      <MediaQuery minWidth={800}>
        <ThemeProvider theme={themeDesktop}>
          {
            side === "receiver" ? <ReceiverApp.App /> : <GiverApp.App />
          }
        </ThemeProvider>
      </MediaQuery>
      <MediaQuery maxWidth={800}>
        <ThemeProvider theme={themeMobile}>
          {
            side === "receiver" ? <ReceiverApp.App /> : <GiverApp.App />
          }
        </ThemeProvider>
      </MediaQuery>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
