import {Box, BoxProps, useTheme} from "@mui/material";
import {MuiChipCustom, SkeletonCustom} from "../theme";
import {Scrollbars} from "react-custom-scrollbars-2";
import Stack from "@mui/system/Stack";
import React, {useContext} from "react";
import {CompanyContext, OrderContext} from "../context";
import {Nominal} from "../model/company";

export function NominalSelector(
    { ...props }: BoxProps
) {
    const theme = useTheme();
    const [company] = useContext(CompanyContext);
    const [order, setOrder] = useContext(OrderContext);
    function onlyUnique(value: Nominal, index: number, array: Array<Nominal>) {
        return array.findIndex(not => not.value === value.value) === index;
    }
    const nominals = company?.nominals.filter(onlyUnique);
    return <Box {...props}>
        {
            company === null || company === undefined ? <SkeletonCustom
                sx={{
                    height: "2rem",
                    mx: "2rem",
                }}
            /> : <Scrollbars
                style={{
                    width: "100%",
                    height: "3rem"
                }}
                renderTrackHorizontal={({style, ...props}) => {
                    return <div {...props} style={{
                        ...style,
                        left: "2rem",
                        right: "2rem",
                        bottom: 0,
                        backgroundColor: "#D9D9D9",
                        height: "0.25rem",
                        borderRadius: "0.125rem"
                    }}/>;
                }}
                renderThumbHorizontal={({style, ...props}) => {
                    return <div {...props} style={{
                        ...style,
                        backgroundColor: theme.palette.primary.main,
                        height: "0.25rem",
                        borderRadius: "0.125rem"
                    }}/>;
                }}
                autoHide
            >
                <Stack
                    direction="row"
                    spacing="0.5rem"
                >
                    <Box sx={{minWidth: "1.5rem"}}/>
                    {nominals?.map((data) => {
                        return (
                            <MuiChipCustom
                                key={data.id}
                                label={`${data.value} ₽`}
                                color={order.nominal?.id === data.id ? "primary" : undefined}
                                sx={{
                                    color: order.nominal?.id === data.id ? "#ffffff" : theme.palette.primary.main
                                }}
                                onClick={() => {
                                    const o = {
                                        ...order,
                                        nominal: data
                                    };
                                    setOrder(o);
                                }}
                            />
                        );
                    })}
                    <Box sx={{minWidth: "1.5rem"}}/>
                </Stack>
            </Scrollbars>
        }
    </Box>
}
