import React, {useEffect, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Loading from "../../widgets/loading";
import Stack from "@mui/system/Stack";
import {List, Typography} from "@mui/material";

export function RulesPage() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [rules, setRules] = useState<Array<String> | null>(null);
    const hrefSplit = window.location.href.split("/");
    const token = hrefSplit.length > 1 ? hrefSplit.slice(-1)[0] : null;
    useEffect(() => {
        const BASE_URL = process.env.REACT_APP_API_URL;
        const fetchData = async () => {
            if (!token) {
                return;
            }
            const r: Array<String> = (
                await axios.get(`${BASE_URL}/public/rules/${token}`)
            ).data;
            setRules(r);
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    });
    if (isLoading || rules === null) {
        return <Loading />;
    }
    return (
        <Stack>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    color: "#474748",
                    mt: "2rem",
                    mx: "0.5rem",
                    alignSelf: "start"
                }}
            >
                Правила
            </Typography>
            <List>
                {
                    rules.map((rules, index) => (
                        <Typography>
                            {index + 1}. {rules}
                        </Typography>
                    ))
                }
            </List>
        </Stack>
    );
}
